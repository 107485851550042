import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LocaleKey } from 'utils';

import {
  Image,
  LangMenu,
  Slider,
  TitleText,
} from 'components';
import { WhiteContainer } from 'containers';

import { tutorials } from '../instruments';

import styles from '../styles.module.scss';

export const TutorialContainer = () => {
  const { t } = useTranslation('news');
  const language = localStorage.getItem('lng') || LocaleKey.en;

  const [langBanner, setLangBanner] = useState(language);
  const [isView, setIsView] = useState(true);

  useEffect(() => {
    setIsView(false);
    setLangBanner(language);
    setTimeout(() => {
      setIsView(true);
    }, 0);
  }, [language]);

  // const videoBaseUrl = 'https://storage.googleapis.com/eclipse-cdn/';

  // const videoMap: Record<string, string> = {
  //   'Tutorial-product-br': 'videos/Tutorial-product-br.mp4',
  //   'Tutorial-product-de': 'videos/Tutorial-product-de.mp4',
  //   'Tutorial-product-en': 'videos/Tutorial-product-en.mp4',
  //   'Tutorial-product-es': 'videos/Tutorial-product-es.mp4',
  //   'Tutorial-product-fr': 'videos/Tutorial-product-fr.mp4',
  //   'Tutorial-product-hy': 'videos/Tutorial-product-hy.mp4',
  //   'Tutorial-product-it': 'videos/Tutorial-product-it.mp4',
  //   'Tutorial-product-pt': 'videos/Tutorial-product-pt.mp4',

  //   'Tutorial-agent-br': 'videos/Tutorial-agent-br.mp4',
  //   'Tutorial-agent-de': 'videos/Tutorial-agent-de.mp4',
  //   'Tutorial-agent-en': 'videos/Tutorial-agent-en.mp4',
  //   'Tutorial-agent-es': 'videos/Tutorial-agent-es.mp4',
  //   'Tutorial-agent-fr': 'videos/Tutorial-agent-fr.mp4',
  //   'Tutorial-agent-hy': 'videos/Tutorial-agent-en.mp4',
  //   'Tutorial-agent-it': 'videos/Tutorial-agent-it.mp4',
  //   'Tutorial-agent-pt': 'videos/Tutorial-agent-pt.mp4',

  //   'Tutorial-guideline-br': 'files/Tutorial-guideline-br.pdf',
  //   'Tutorial-guideline-de': 'files/Tutorial-guideline-de.pdf',
  //   'Tutorial-guideline-en': 'files/Tutorial-guideline-en.pdf',
  //   'Tutorial-guideline-es': 'files/Tutorial-guideline-es.pdf',
  //   'Tutorial-guideline-fr': 'files/Tutorial-guideline-en.pdf',
  //   'Tutorial-guideline-hy': 'files/Tutorial-guideline-hy.pdf',
  //   'Tutorial-guideline-it': 'files/Tutorial-guideline-it.pdf',
  //   'Tutorial-guideline-pt': 'files/Tutorial-guideline-pt.pdf',
  // };

  // const handleVideoFileUrls = (videoFiles: string): string => {
  //   const videoKey = `${videoFiles}${langBanner || language}`;
  //   return videoMap[videoKey] ? `${videoBaseUrl}${videoMap[videoKey]}` : '';
  // };

  const videoBaseUrl = 'https://storage.googleapis.com/eclipse-cdn/';
  const tutorialTypes = ['product', 'agent', 'guideline'] as const;
  const languageCodes = ['br', 'de', 'en', 'es', 'fr', 'hy', 'it', 'pt'] as const;

  const videoMap: Record<string, string> = tutorialTypes.reduce((acc, type) => {
    languageCodes.forEach((lang) => {
      const basePath = type === 'guideline' ? 'files' : 'videos';
      const fileExtension = type === 'guideline' ? 'pdf' : 'mp4';
      const key = `Tutorial-${type}-${lang}`;
      acc[key] = `${basePath}/${key}.${fileExtension}`;
    });
    return acc;
  }, {} as Record<string, string>);

  const handleVideoFileUrls = (videoFiles: string): string => {
    const videoKey = `${videoFiles}${langBanner || language}`;
    return videoMap[videoKey] ? `${videoBaseUrl}${videoMap[videoKey]}` : '';
  };

  const items = useMemo(() => (
    tutorials.map(({
      icon,
      title,
      video,
      videoFile,
    }) => {
      const fileUrl = handleVideoFileUrls(videoFile);
      return (
        <WhiteContainer
          className={styles.instruments_item}
        >
          <a
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            href={video[langBanner]}
            target="_blank"
            rel="noreferrer"
          >
            <Image
              url={icon}
              className={styles.instruments_img}
            />
          </a>
          <div className={styles.instruments_title}>
            {t(title)}
            {' '}
          </div>
          <a
            href={fileUrl}
            download
            target="_blank"
            rel="noreferrer"
            className={styles.instruments_btn}
          >
            {t('Instruments_saved_')}
          </a>
        </WhiteContainer>
      );
    })
  ), [t, langBanner, language]);

  return (
    <div className={styles.instruments_content}>
      <div className={styles.instruments_content_top}>
        <TitleText
          className={styles.instruments_content_title}
          text={t('Tutorials_')}
        />

        {isView && (
          <LangMenu
            langBanner={langBanner}
            onChangeLangBanner={setLangBanner}
            classNameMenu={styles.instruments_lang}
          />
        )}
      </div>
      <Slider items={items} />
    </div>
  );
};
