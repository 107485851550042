import {
  aboutUsImage,
  marketingPlanImage,
  // rewardsImage,
  agentImage,
  productImage,
  guidelineImage,
  roadmapImage,
  planRewardsImage,
  superAppImage,
} from 'assets/images';

export const instruments = [
  {
    title: 'Instruments_about_us_',
    video: {
      en: 'https://www.youtube.com/watch?v=18-Sr8Wgqog',
      de: 'https://www.youtube.com/watch?v=YfKaFjHuh6s',
      es: 'https://www.youtube.com/watch?v=UD0UgPT06is',
      fr: 'https://www.youtube.com/watch?v=K_BLYxmnS4I',
      hy: 'https://www.youtube.com/watch?v=IEgZxyAzffc',
      it: 'https://www.youtube.com/watch?v=Xk4C4Pc8yRQ',
      pt: 'https://www.youtube.com/watch?v=sRG7fJGgxCk&list=PLv2bCnfMARBzD29EM3o67c4qU_zgxqn_7&index=3',
      br: 'https://www.youtube.com/watch?v=Ake_GefmGuI&list=PLv2bCnfMARByKpiRtctcDxRioedALZhzI&index=4',
    },
    icon: aboutUsImage,
    videoFile: 'About-us-',
  },
  {
    title: 'Instruments_marketing_plan_',
    video: {
      en: 'https://www.youtube.com/watch?v=4YXjs4SKw_o',
      de: 'https://www.youtube.com/watch?v=f-LnpA6IKBU',
      es: 'https://www.youtube.com/watch?v=rBy9ccrAcbQ',
      fr: 'https://www.youtube.com/watch?v=4YXjs4SKw_o',
      hy: 'https://www.youtube.com/watch?v=4YXjs4SKw_o',
      it: 'https://www.youtube.com/watch?v=rlRlnrkfWX8',
      pt: 'https://www.youtube.com/watch?v=O6-EffAOe8s',
      br: 'https://www.youtube.com/watch?v=4YXjs4SKw_o',
    },
    icon: marketingPlanImage,
    videoFile: 'Marketing-plan-',
  },
  {
    title: 'Instruments_SuperApp_',
    video: {
      en: 'https://www.youtube.com/watch?v=ZcZsuiGrse0',
      de: 'https://www.youtube.com/watch?v=KUbAGsn0mBg',
      es: 'https://www.youtube.com/watch?v=eXPcyE4uOf4',
      fr: 'https://www.youtube.com/watch?v=ZcZsuiGrse0',
      hy: 'https://www.youtube.com/watch?v=BS8Q21wfBpU',
      it: 'https://www.youtube.com/watch?v=M57VUDTpbiM',
      pt: 'https://www.youtube.com/watch?v=ZcZsuiGrse0',
      br: 'https://www.youtube.com/watch?v=ZcZsuiGrse0',
    },
    icon: superAppImage,
    videoFile: 'About_hub_',
  },
  // {
  //   title: 'Instruments_rewards_',
  //   video: {
  //     en: 'https://www.youtube.com/watch?v=SrLxULCwM-Q',
  //     de: 'https://www.youtube.com/watch?v=B_SLlBoRfYI',
  //     es: 'https://www.youtube.com/watch?v=26k-Oe4yA-M',
  //     fr: 'https://www.youtube.com/watch?v=Wg2aDZcHz2U',
  //     hy: 'https://www.youtube.com/watch?v=6tYG-wFr64g',
  //     it: 'https://www.youtube.com/watch?v=Dat8jwIKBck',
  //     pt: 'https://www.youtube.com/watch?v=O3Ur0uPufJ8&list=PLv2bCnfMARBzD29EM3o67c4qU_zgxqn_7&index=1',
  //     br: 'https://www.youtube.com/watch?v=g1QMizMPVHY&list=PLv2bCnfMARByKpiRtctcDxRioedALZhzI&index=1',
  //   },
  //   icon: rewardsImage,
  //   videoFile: 'reward-',
  // },
];

export const tutorials = [
  {
    title: 'Tutorial_agent_',
    video: {
      en: 'https://www.youtube.com/watch?v=3dXO_LsP51w',
      de: 'https://www.youtube.com/watch?v=3zig3BaX6p4',
      es: 'https://www.youtube.com/watch?v=fEELXbkiGE8',
      fr: 'https://www.youtube.com/watch?v=vg5K80pj5oM',
      hy: 'https://www.youtube.com/watch?v=3dXO_LsP51w',
      it: 'https://www.youtube.com/watch?v=J_R75s2SLug',
      pt: 'https://www.youtube.com/watch?v=RQO7uL2we0Q',
      br: 'https://www.youtube.com/watch?v=vKOSKJdzOo8',
    },
    icon: agentImage,
    videoFile: 'Tutorial-agent-',
  },
  {
    title: 'Tutorial_product_',
    video: {
      en: 'https://www.youtube.com/watch?v=Zkxu5xEGSMo',
      de: 'https://www.youtube.com/watch?v=LOxqkIp86WA',
      es: 'https://www.youtube.com/watch?v=MNDUhuciGWM',
      fr: 'https://www.youtube.com/watch?v=TB9COnGivPU',
      hy: 'https://www.youtube.com/watch?v=biGwWGQLxGI',
      it: 'https://www.youtube.com/watch?v=brnGqIZCazE',
      pt: 'https://www.youtube.com/watch?v=czQ77648umI',
      br: 'https://www.youtube.com/watch?v=czQ77648umI',
    },
    icon: productImage,
    videoFile: 'Tutorial-product-',
  },
  {
    title: 'Tutorial_guideline_',
    video: {
      en: 'https://docs.google.com/document/d/1-zOqpBxoPkfN52jsM2X0LjyaY0VjP_3v/edit',
      de: 'https://docs.google.com/document/d/12ZgkClu0TBD70EtZ6ygZxJW3dzTfHfMW/edit',
      es: 'https://docs.google.com/document/d/1SGZUereiH_ZWL-8qzxE7Y5updVRa9TRtIKObn4hpn6E/edit?tab=t.0',
      fr: 'https://docs.google.com/document/d/1_zlwBw6nHMXMOmIvNPyc0nm6ZVfj36lstCzbrUR8rIM/edit?tab=t.0',
      hy: 'https://docs.google.com/document/d/1EeRpf62MXrYYmdQy-iddvmi918kedLiP/edit',
      it: 'https://docs.google.com/document/d/1JK9tY85p_YLlbiP_wywzY9bKY5uW8Sic/edit',
      pt: 'https://docs.google.com/document/d/1TepLMoEnvWNKvn3iQJfW4FcLR1lETlHeJss6PesKppI/edit?tab=t.0',
      br: 'https://docs.google.com/document/d/1TepLMoEnvWNKvn3iQJfW4FcLR1lETlHeJss6PesKppI/edit?tab=t.0',
    },
    icon: guidelineImage,
    videoFile: 'Tutorial-guideline-',
  },
];

export const banners = [
  {
    title: '1080x1080',
    imgLink: 'banner_1080x1080',
  },
  {
    title: '200x200',
    imgLink: 'banner_200x200',
  },
  {
    title: '240x400',
    imgLink: 'banner_240x400',
  },
  {
    title: '250x250',
    imgLink: 'banner_250x250',
  },
  {
    title: '250x360',
    imgLink: 'banner_250x360',
  },
  {
    title: '300x250',
    imgLink: 'banner_300x250',
  },
  {
    title: '336x280',
    imgLink: 'banner_336x280',
  },
  {
    title: '580x400',
    imgLink: 'banner_580x400',
  },
  {
    title: '468x60',
    imgLink: 'banner_468x60',
  },
  {
    title: '728x90',
    imgLink: 'banner_728x90',
  },
  {
    title: '930x180',
    imgLink: 'banner_930x180',
  },
  {
    title: '970x90',
    imgLink: 'banner_970x90',
  },
  {
    title: '970x250',
    imgLink: 'banner_970x250',
  },
  {
    title: '980x120',
    imgLink: 'banner_980x120',
  },
  {
    title: '120x600',
    imgLink: 'banner_120x600',
  },
  {
    title: '160x600',
    imgLink: 'banner_160x600',
  },
  {
    title: '300x600',
    imgLink: 'banner_300x600',
  },
  {
    title: '300x1050',
    imgLink: 'banner_300x1050',
  },
  {
    title: '300x50',
    imgLink: 'banner_300x50',
  },
  {
    title: '320x50',
    imgLink: 'banner_320x50',
  },
  {
    title: '320x100',
    imgLink: 'banner_320x100',
  },
  {
    title: '480x320',
    imgLink: 'banner_480x320',
  },
  {
    title: '320x480',
    imgLink: 'banner_320x480',
  },
  {
    title: '504x283',
    imgLink: 'banner_504x283',
  },
  {
    title: '1080x1920',
    imgLink: 'banner_1080x1920',
  },
];

export const Documents = [
  {
    title: 'Document_Title',
    video: {
      en: 'https://storage.googleapis.com/eclipse-cdn/files/Roadmap_en.pdf',
      de: 'https://storage.googleapis.com/eclipse-cdn/files/Roadmap_de.pdf',
      es: 'https://storage.googleapis.com/eclipse-cdn/files/Roadmap_es.pdf',
      fr: 'https://storage.googleapis.com/eclipse-cdn/files/Roadmap_fr.pdf',
      hy: 'https://storage.googleapis.com/eclipse-cdn/files/Roadmap_hy.pdf',
      it: 'https://storage.googleapis.com/eclipse-cdn/files/Roadmap_it.pdf',
      pt: 'https://storage.googleapis.com/eclipse-cdn/files/Roadmap_pt.pdf',
      br: 'https://storage.googleapis.com/eclipse-cdn/files/Roadmap_pt.pdf',
    },
    icon: roadmapImage,
    videoFile: 'Roadmap_',
  },
  {
    title: 'Plan_rewards_',
    video: {
      en: 'https://storage.googleapis.com/eclipse-cdn/files/Plan_rewards_en.pdf',
      de: 'https://storage.googleapis.com/eclipse-cdn/files/Plan_rewards_en.pdf',
      es: 'https://storage.googleapis.com/eclipse-cdn/files/Plan_rewards_en.pdf',
      fr: 'https://storage.googleapis.com/eclipse-cdn/files/Plan_rewards_en.pdf',
      hy: 'https://storage.googleapis.com/eclipse-cdn/files/Plan_rewards_en.pdf',
      it: 'https://storage.googleapis.com/eclipse-cdn/files/Plan_rewards_it.pdf',
      pt: 'https://storage.googleapis.com/eclipse-cdn/files/Plan_rewards_en.pdf',
      br: 'https://storage.googleapis.com/eclipse-cdn/files/Plan_rewards_en.pdf',
    },
    icon: planRewardsImage,
    videoFile: 'Plan_rewards_',
  },
];
