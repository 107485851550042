import React from 'react';
import { BannerContainer } from './BannerContainer';
import { VideoContainer } from './VideoContainer';
import { TutorialContainer } from './TutorialContainer';
import { DocumentContainer } from './DocumentContainer';

import styles from './styles.module.scss';

// const baseURL = process.env.REACT_APP_URL;

export const InstrumentsPage = () => (
  <div className={styles.instruments_container}>
    <VideoContainer />
    <TutorialContainer />
    {/* <BannerContainer
        pack={1}
        href={`${baseURL}/signup?ref=${referralCode}`}
      /> */}
    <BannerContainer
      pack={2}
    />
    <BannerContainer
      pack={3}
    />
    <DocumentContainer />
  </div>
);
