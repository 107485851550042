import React, { FC, memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { useScreenWidth } from 'hooks';
import { ScreenWidth } from 'appConstants';
import { LanguageContext } from 'context/languageContext';

import { Image } from 'components';
import { linkYoutubeIcon } from 'assets/images';
import styles from './styles.module.scss';

interface ILinksSocialFooter {
  links: { link: string, icon: string, title?: string }[];
  classNameContainer: string;
  showYoutube?: boolean;
}

export const LinksSocialFooter:FC<ILinksSocialFooter> = memo(({
  links,
  classNameContainer,
  showYoutube,
}) => {
  const { t } = useTranslation('footer');
  const isLaptop = useScreenWidth(ScreenWidth.laptop);
  const { currentLanguage } = useContext(LanguageContext);

  let YoutubeUrl;
  switch (currentLanguage) {
    case 'en':
      YoutubeUrl = 'https://www.youtube.com/watch?v=ZcZsuiGrse0&list=PLv2bCnfMARBxAiJXFBCu8vJ_etMOykw-C';
      break;
    case 'de':
      YoutubeUrl = 'https://www.youtube.com/watch?v=YfKaFjHuh6s&list=PLv2bCnfMARBznjhpK4-itt7wNs6kTZR2R';
      break;
    case 'es':
      YoutubeUrl = 'https://www.youtube.com/watch?v=UD0UgPT06is&list=PLv2bCnfMARBzBdVLFZgjzTQPDi5gX8mi_';
      break;
    case 'fr':
      YoutubeUrl = 'https://www.youtube.com/watch?v=K_BLYxmnS4I&list=PLv2bCnfMARByLKF3C0XnKEeaGJ9mKldAU';
      break;
    case 'it':
      YoutubeUrl = 'https://www.youtube.com/watch?v=Xk4C4Pc8yRQ&list=PLv2bCnfMARByQWehmbt2mbQUFuM1QYe_i';
      break;
    case 'pt':
      YoutubeUrl = 'https://www.youtube.com/watch?v=O6-EffAOe8s&list=PLv2bCnfMARBzD29EM3o67c4qU_zgxqn_7';
      break;
    case 'hy':
      YoutubeUrl = 'https://www.youtube.com/watch?v=IEgZxyAzffc&list=PLv2bCnfMARBwZk_oHN89HaCAJAVP2PTdV';
      break;
    case 'br':
      YoutubeUrl = 'https://www.youtube.com/watch?v=Ake_GefmGuI&list=PLv2bCnfMARByKpiRtctcDxRioedALZhzI';
      break;
    default:
      break;
  }

  return (
    <div className={cn(styles.footer_social, classNameContainer)}>
      {links.map(({ icon, link, title }) => (
        <a
          key={title}
          href={link}
          target="_blank"
          rel="noreferrer"
          className={styles.footer_social_link}
        >
          <Image
            url={icon}
            className={styles.footer_social_link_icon}
          />
          {title && !isLaptop && (
            <div className={styles.footer_social_link_text}>
              {t(title)}
            </div>
          )}
        </a>
      ))}
      {showYoutube && (
      <a
        href={YoutubeUrl}
        target="_blank"
        rel="noreferrer"
        className={styles.footer_social_link}
      >
        <Image
          url={linkYoutubeIcon}
          className={styles.footer_social_link_icon}
        />
      </a>
      )}
    </div>
  );
});
