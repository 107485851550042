import React, {
  FC,
  memo,
  useCallback, useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';

import { LocaleKey } from 'utils';
import { PathName } from 'appConstants';

import {
  Image,
  LangMenu,
  Slider,
  TitleText,
  toastSuccess,
} from 'components';
import { WhiteContainer } from 'containers';

import { profileSelectors } from 'store/profile/selectors';
import { banners } from '../instruments';

import styles from '../styles.module.scss';

interface ILangMenu {
  pack: number;
}

export const BannerContainer: FC<ILangMenu> = memo(({
  pack,
}) => {
  const isHost = window.location.host.includes('test');
  const baseURL = isHost ? PathName.AppUrlTest : PathName.AppUrl;
  const { referralCode } = useSelector(profileSelectors.getState);
  const referralLink = `https://agent.eclipse.club/signup?ref=${referralCode}`;

  const { t } = useTranslation('news');
  const language = localStorage.getItem('lng') || LocaleKey.en;

  const [langBanner, setLangBanner] = useState(language);
  const [isView, setIsView] = useState(true);

  useEffect(() => {
    setIsView(false);
    setLangBanner(language);
    setTimeout(() => {
      setIsView(true);
    }, 0);
  }, [language]);

  const onCopy = useCallback((title:string) => {
    toastSuccess(title);
  }, []);

  const bannerMap = useCallback((packNumber: number) => (
    banners.map(({ title, imgLink }) => {
      const code = `<a
              href="https://eclipse.club/?ref=${referralCode}"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="${baseURL}/banners/pack${packNumber}/${langBanner || language}/${imgLink}.png"
                alt="banner"
              />
            </a>`;

      return (
        <WhiteContainer className={styles.instruments_item}>
          <a
            href={`${baseURL}/banners/pack${packNumber}/${langBanner || language}/${imgLink}.png`}
            download
            target="_blank"
            rel="noreferrer"
          >
            <Image
              url={`${baseURL}/banners/pack${packNumber}/preview/banner_${langBanner || language}.png`}
              className={styles.instruments_img}
            />
          </a>
          <div className={styles.instruments_title}>
            {title}
          </div>
          <div className={styles.instruments_btn_container}>
            <CopyToClipboard text={referralLink || ''}>
              <button
                className={styles.instruments_btn}
                onClick={() => onCopy(t('Link_copied_'))}
              >
                {t('Instruments_copy_referral_link_')}
              </button>
            </CopyToClipboard>
            <CopyToClipboard text={code || ''}>
              <button
                className={styles.instruments_btn}
                onClick={() => onCopy(t('Code_copied_'))}
              >
                {t('Instruments_show_code_')}
              </button>
            </CopyToClipboard>
          </div>
        </WhiteContainer>
      );
    })
  ), [langBanner, language, t, onCopy, baseURL, referralCode, referralLink]);

  return (
    <div className={styles.instruments_content}>
      <div className={styles.instruments_content_top}>
        <TitleText
          className={styles.instruments_content_title}
          text={t('Instruments_banners_')}
        />

        {isView && (
          <LangMenu
            langBanner={langBanner}
            onChangeLangBanner={setLangBanner}
            classNameMenu={styles.instruments_lang}
          />
        )}
      </div>
      <Slider items={bannerMap(pack)} />
    </div>
  );
});
