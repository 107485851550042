export const PathName = {
  Home: '/',
  HomeFooter: '/legal_information',
  SignIn: '/sign-in',
  Registration: '/signup',
  ForgotPassword: '/forgot-password',

  Cabinet: '/cabinet',
  Profile: '/cabinet/profile',
  Partner: '/cabinet/partner',
  Status: '/cabinet/status',
  Pool: '/cabinet/pool',
  Instruments: '/cabinet/instruments',
  Transactions: '/cabinet/transactions',
  Support: '/cabinet/support',
  Faq: '/cabinet/faq',
  ItemId: ':id',
  News: '/cabinet/news',
  NewsItem: '/cabinet/news/new',
  Documents: '/cabinet/documents',
  DocumentsItem: '/cabinet/documents/document',

  WhatWeOffer: 'what-we-offer',
  Sellers: 'sellers',
  ConnectUs: 'connect-with-us',
  Telegram: 'https://t.me/globalhubeclipse',
  Twitter: 'https://twitter.com/ghubeclipse',
  Facebook: 'https://www.facebook.com/profile.php?id=61555251314245',
  LinkedIn: 'https://www.linkedin.com/company/eclipse-global-hub/',
  Instagram: 'https://www.instagram.com/eclipse_global_hub?igsh=MXBxcHNhZmZzN2VkbA%3D%3D&utm_source=qr',
  Youtube: 'https://www.youtube.com/@EclipseGlobalHub',
  GooglePlay: '#',
  AppStore: '#',
  MacOS: '#',
  Web: '#',
  Windows: '#',

  LegalInfo: 'https://eclipse.club/docs/legal',
  PersonalData: 'https://eclipse.club/docs/personal',
  AntiCorruption: 'https://eclipse.club/docs/anticorruption',
  OrderAndProviding: 'https://eclipse.club/docs/order',
  PaymentRules: 'https://eclipse.club/docs/paymentrules',
  AppUrlTest: 'https://test.eclipse.club',
  AppUrl: 'https://agent.eclipse.club',
  AppMainUrlTest: 'https://staging.internal.eclipse.club',
  AppMainUrl: 'https://eclipse.club',
  AppHubUrlTest: 'https://staging.internal.eclipse.club',
  AppHubUrl: 'https://hub.eclipse.club',
};
