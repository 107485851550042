import React, {
  createContext,
  useMemo,
  useCallback,
  ReactNode,
  useState,
} from 'react';

type LanguageContextProps = {
  currentLanguage: string;
  setLanguage: (language: string) => void;
};

export const LanguageContext = createContext<LanguageContextProps>({
  currentLanguage: localStorage.getItem('lng') || 'en',
  setLanguage: () => {},
});

export const LanguageProvider = ({ children }: { children: ReactNode }) => {
  const [currentLanguage, setCurrentLanguage] = useState<string>('en');

  const setLanguage = useCallback((language: string) => {
    setCurrentLanguage(language);
  }, []);

  const value = useMemo(
    () => ({ currentLanguage, setLanguage }),
    [currentLanguage, setLanguage],
  );

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};
